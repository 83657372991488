import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, MainHeading } from "../components/styled/Styled"
import ProductionList from "../components/lists/ProductionList"

const ProductionPage = () => {
  return (
    <Layout>
      <SEO title="Productions" />
      <Container>
        <MainHeading style={{ textAlign: "center" }}>PRODUCTIONS</MainHeading>
        <ProductionList />
      </Container>
    </Layout>
  )
}

export default ProductionPage
