import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { InternalLink, Text, Subheading } from "../styled/Styled"
import { MEDIA_QUERIES, COLORS, formatDate } from "../../constants"

const Productions = styled(motion.div)`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: start;
`

const ProductionItem = styled(motion.div)`
  position: relative;
  display: flex;
  width: 48%;
  height: 350px;

  margin: 0 1%;
  margin-top: 1%;
  background-color: ${COLORS.GREY};
  @media (max-width: ${MEDIA_QUERIES.LARGE}) {
    width: 100%;
    height: 500px;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`

const ProductionImage = styled(Image)`
  width: 50%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
    height: 350px;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    height: 250px;
  }
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 3%;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    a {
      align-self: flex-end;
    }
    width: 100%;
  }
`

const Date = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
  width: 50%;
  font-size: 0.8rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 32.666%;
  }
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    width: 100%;
    bottom: auto;
    top: 0;
  }

  p {
    background-color: ${COLORS.GREY};
    padding: 0.5rem;
    border-bottom-right-radius: 5px;
  }
`

const ListVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { delayChildren: 0.2, staggerChildren: 0.25 },
  },
}

const ItemVariants = {
  initial: {
    y: "200px",
    opacity: 0,
  },
  animate: { opacity: 1, y: 0, transition: { duration: 0.75, damping: 50 } },
}

const ProductionList = () => {
  const {
    allMarkdownRemark: { nodes: productions },
  } = useStaticQuery(graphql`
    query ProductionQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "production" } } }
      ) {
        nodes {
          rawMarkdownBody
          fields {
            slug
          }
          frontmatter {
            title
            excerpt
            start
            end
            bannerimage {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            featuredimage {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            gallery {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Productions initial="initial" animate="animate" variants={ListVariants}>
      {productions &&
        productions.map(({ fields: { slug }, frontmatter: production }, i) => {
          const excerpt =
            production.excerpt.length >= 180
              ? `${production.excerpt.substring(0, 180)}...`
              : production.excerpt
          const start = formatDate(production.start)
          const end = formatDate(production.end)
          return (
            <ProductionItem variants={ItemVariants} key={i}>
              <ProductionImage
                fluid={production.featuredimage.childImageSharp.fluid}
              />
              <Article>
                <Subheading style={{ marginTop: "1rem" }}>
                  {production.title}
                </Subheading>
                <Text style={{ fontSize: "1.1rem" }}>{excerpt}</Text>
                <InternalLink
                  style={{
                    marginRight: 0,
                    marginLeft: "auto",
                    marginTop: "auto",
                    marginBottom: "1rem",
                  }}
                  to={slug}
                >
                  Read more
                </InternalLink>
              </Article>
              {production.start && (
                <Date>
                  <p style={{ marginRight: "auto" }}>
                    {start} {production.end && production.start && `- ${end}`}
                  </p>
                </Date>
              )}
            </ProductionItem>
          )
        })}
    </Productions>
  )
}

ProductionList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ProductionList
